/* General spacing */

body {
    padding: 13px 15px 15px 15px;
}

/* Typography */

@font-face {
    font-family: "suisse";
    src: url("../fonts/SuisseBPIntl-Book.woff2");
}

body {
    font-family: "suisse", helvetica, arial, sans-serif;
    font-size: 13px;
    line-height: 1.2em;
}

.t0 {
    font-size: 0.7rem;
    line-height: 1;
}

.t1 {
    font-size: 1rem;
    line-height: 0.95rem;
}

.t2 {
    font-size: 1.4rem;
    line-height: 1.5rem;
    letter-spacing: -0.3px;
}

.t3 {
    font-size: 1.96rem;
    line-height: 1.5rem;
}

.t4 {
    font-size: 2.744rem;
    line-height: 1.5rem;
}

h1 {
    font-size: 2.744rem;
    line-height: 2.8rem;
    letter-spacing: -1px;
}

/* Vertical Space modules */

.mb0 {
    margin-bottom: calc(15px / 1.4);
}

.mb1 {
    margin-bottom: 15px;
}

.mb2 {
    margin-bottom: calc(15px * 1.4);
}

.mb2 {
    margin-bottom: calc(15px * 1.4 * 1.4);
}

.mb3 {
    margin-bottom: calc(15px * 1.4 * 1.4 * 1.4);
}

.mb4 {
    margin-bottom: calc(15px * 1.4 * 1.4 * 1.4 * 1.4);
}

.mt0 {
    margin-top: calc(15px / 1.4);
}

.mt1 {
    margin-top: 15px;
}

.mt2 {
    margin-top: calc(15px * 1.4);
}

.mt2 {
    margin-top: calc(15px * 1.4 * 1.4);
}

.mt3 {
    margin-top: calc(15px * 1.4 * 1.4 * 1.4);
}

.mt4 {
    margin-top: calc(15px * 1.4 * 1.4 * 1.4 * 1.4);
}

/* Anchors */

a {
    color: #000000;
    text-decoration: underline;
    cursor: pointer;
}

a:hover {
    color: #b1b1b1;
    text-decoration: none;
}

/* Responsive: Mobile */
@media screen and (max-width: 480px) {
}

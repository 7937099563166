.header {
    width: 75%;
}

/* Responsive: Mobile */
@media screen and (max-width: 480px) { 

    .header{
        width: 100%;
    }

}
.about-content {
    width: 100%;
    display: flex;
    flex-flow: row;
}

.about-curriculum {
    display: flex;
    flex-flow: column;
    width: 50%;
}

.about-credits {
    display: flex;
    flex-flow: column;
    width: 50%;
}

.about-box-container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    /* background-color: chartreuse; */
}

.about-box-label {
    width: 25%;
    /* background-color: blueviolet; */
}

.about-box-content {
    width: 75%;
    /* background-color: brown; */
}

/* Responsive: Mobile */
@media screen and (max-width: 480px) {
    .about-content {
        flex-flow: column;
        margin-bottom: calc(15px * 1.4 * 1.4);
    }

    .about-curriculum {
        order: 0;
        width: 100%;
        margin-bottom: calc(15px * 1.4);
    }

    .about-credits {
        order: 1;
        line-height: 1.4em;
        margin-bottom: calc(15px * 1.4 * 1.4 * 1.4 * 1.4);
    }
}


.slideshow-container{
    background-color: black;
    color: white;
    display: flex;
    flex-flow: column wrap;
}

.slideshow-close{
    text-align: left;
}
.slideshow-close span{
    text-decoration: underline;
    cursor: pointer;
}

.slideshow-close span:hover{
    text-decoration: none;
    color: darkgrey;
}

.slideshow-stage{
    display: flex;
    height: 87vh;
    align-content: center;
    /* Metto in posizione assoluta e traslo della dimensione della colonna di testo... */
}

.slideshow-controls{
    position: absolute;
    display: flex;
    flex-flow: row;
    z-index: 5;
    /* Larghezza = totale - 2 volte il margine */
    width: calc(100% - 15px - 15px); 
    height: 83vh;
}


.slideshow-ctrls-left{
    position: relative;
    z-index: 9;
    width: 50%;
    height: 100%;
    /* background-color: red; */
    cursor: url('../../images/curssor_arrowL.png'), auto;	
}
.slideshow-ctrls-right{
    position: relative;
    z-index: 9;
    width: 50%;
    height: 100%;
    /* background-color: blue; */
    cursor: url('../../images/curssor_arrowR.png'), auto;	
}

.slideshow-stage img{
    max-height: 100%;
    width: auto;
    margin-left: auto;
    margin-right: auto;
}

.slideshow-info{
    text-align: center;
}

.slideshow-info span{
    text-decoration: underline;
    cursor: pointer;
}

.slideshow-info span:hover{
    text-decoration: none;
    cursor: darkgrey;
}

@media screen and (max-width: 480px) { 

    .slideshow-stage img{
        max-width: 100%;
        max-height: auto;
        overflow: hidden;
        object-fit: contain;
    }

}
.portfolio-container {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
}

.portfolio-leftSide {
    display: flex;
    flex-flow: column wrap;
    width: 25%;
}
.portfolio-pjList {
    width: 100%;
}

.portfolio-stage {
    width: 75%;
}
.pj-detail {
    width: 100%;
}

.porfolio-listAnchor {
    cursor: pointer;
}

.porfolio-listAnchor:hover {
    color: #b1b1b1;
}

.portfolio-stageContainer {
    display: flex;
    flex-flow: column;
}

.portfolio-stageControls {
    position: relative;
    padding-left: 5px;
}

.portfolio-stageControls span {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    text-decoration: none;
}

.portfolio-stageControls span:hover {
    text-decoration: none;
    color: #b1b1b1;
}

.portfolio-stage {
    display: flex;
    /* height: 100vh; */
    overflow-x: scroll;
    scroll-behavior: smooth;
    /* overflow-x: auto; */
    /* Metto in posizione assoluta e traslo della dimensione della colonna di testo... */
    position: absolute;
    left: calc(25% + 15px);
    width: calc(75% - 15px);
    scroll-margin-right: 100px;
}

.portfolio-stage img {
    max-height: 100%;
    width: auto;
    margin-right: calc(15px / 1.4 / 1.4);
    cursor: pointer;
}

#PORTFOLIOSTAGE:last-child:after {
    /* border-right: 15px solid orange; */
    content: "";
    flex: 0 0 10px;
}

/* Responsive: Mobile */
@media screen and (max-width: 480px) {
    .portfolio-container {
        flex-flow: column;
    }

    .portfolio-leftSide {
        display: flex;
        flex-flow: column wrap;
        width: 100%;
    }

    .projects-catLabel {
        font-size: calc(15px * 1.4);
        line-height: 1.3em;
        letter-spacing: -0.3px;
    }

    .portfolio-pjList li {
        font-size: calc(15px * 1.4);
        line-height: 1.3em;
        letter-spacing: -0.3px;
    }

    .portfolio-stage {
        display: flex;
        position: relative;
        width: 100%;
        overflow-x: auto;
        left: 0;
        height: auto !important;
        flex-flow: column;
        /* scroll-margin-right: 100px; */
    }
    .portfolio-stage img {
        margin-bottom: 15px;
        width: auto;
        max-height: none;
        max-width: 100%;
        height: auto;
    }

    .pj-detail {
        width: 100%;
        margin-bottom: 15px;
    }

    .portfolio-stageControls {
        display: none;
    }
}
